.swiper {
  min-height: 200px;
}

.swiper-pagination-bullet {
  border: 2px solid #63666a;
  background-color: white;
  width: 8px;
  height: 8px;
  opacity: 2;
}

.swiper-pagination-bullet-active {
  border: none;
  background-color: grey;
  background: #63666a;
}

.swiper-button-next,
.swiper-button-prev {
  color: #515356;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.swiper-button-prev {
  background-image: url(../../../../styles/images/leftarrow.svg);
}

.swiper-button-next {
  background-image: url(../../../../styles/images/rightarrow.svg);
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin-right: 16px;
}
