.filters .filterInsights:first-child {
  padding-top: 0;
}

.filters .filterInsights {
  padding: 0.625rem 0;
}

.filters .filterInsights .filtersHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.625rem;
}

.filters .filterInsights .filtersHeader button {
  align-self: start;
  text-transform: unset;
  padding-left: 0.25rem;
}
