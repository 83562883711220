@tailwind base;
@tailwind components;
@tailwind utilities;

/*
  https://create-react-app.dev/docs/adding-images-fonts-and-files
  Webpack finds all relative module references in CSS (they
  start with ./) and replaces them with the final paths from
  the compiled bundle.
*/

@font-face {
  font-family: 'Prelo-Black';
  src: url('./styles/fonts/Prelo-Black.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Prelo-BlackItalic';
  src: url('./styles/fonts/Prelo-BlackItalic.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Prelo-Bold';
  src: url('./styles/fonts/Prelo-Bold.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Prelo-BoldItalic';
  src: url('./styles/fonts/Prelo-BoldItalic.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Prelo-Book';
  src: url('./styles/fonts/Prelo-Book.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Prelo-BookItalic';
  src: url('./styles/fonts/Prelo-BookItalic.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Prelo-Light';
  src: url('./styles/fonts/Prelo-Light.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Prelo-LightItalic';
  src: url('./styles/fonts/Prelo-LightItalic.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Prelo-Medium';
  src: url('./styles/fonts/Prelo-Medium.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Prelo-MediumItalic';
  src: url('./styles/fonts/Prelo-MediumItalic.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

/* TODO: refactor to a scrollbar component */

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-radius: 15px;
  border: 1px solid #cccccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #63666a;
}

*:not(textarea):not([tabindex='-1']):not(a:has(svg)):focus-visible:not([placeholder='mm/dd/yyyy']):not([placeholder='MM/DD/YYYY']):not(.MuiAutocomplete-input),
.MuiInputBase-root:focus-within {
  box-shadow: inset 0 0 0 0.125rem black !important;
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
input[type='checkbox']:focus-visible + svg {
  border-width: 0.125rem;
  border-color: black;
  transition: border 125ms cubic-bezier(0.4, 0, 0.2, 1);
}

main:focus-visible {
  outline: 0.125rem solid black;
  transition: outline 125ms cubic-bezier(0.4, 0, 0.2, 1);
}

a:focus {
  outline: 0;
}

/* Spacing provided to keep focus indicator away from overlapping with table header text.
  Example: Engagements and docs table*/
.MuiTable-root
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-head
  .MuiButton-root {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
/*Spacing provided to keep focus indicator away from overlapping with table header text.
  Example:Doc request table*/
.MuiBox-root .MuiGrid-container .MuiGrid-item .MuiButton-root > div > h5 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.RsmBody-RsmModal-open {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Rsm-scrollBoundaries {
  background: linear-gradient(white 35%, rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), white 65%) center bottom,
    radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0)
      )
      center top,
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0)
      )
      center bottom;
  background-attachment: local, local, scroll, scroll;
  background-repeat: no-repeat;
  background-size: 100% 1rem, 100% 1rem, 100% 0.25rem, 100% 0.25rem;
}
